@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-Black.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-Bold.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-Medium.otf") format("opentype");
	font-style: normal;
}

@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-Regular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Konnect";
	src: url("/fonts/Konnect-SemiBold.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "IntegralCF-Bold";
	src: url("/fonts/IntegralCF-Bold.otf") format("opentype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-BoldOblique";
	src: url("/fonts/IntegralCF-BoldOblique.otf") format("opentype");
	font-weight: bold;
	font-style: oblique;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-DemiBold";
	src: url("/fonts/IntegralCF-DemiBold.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-DemiBoldOblique";
	src: url("/fonts/IntegralCF-DemiBoldOblique.otf") format("opentype");
	font-weight: 600;
	font-style: oblique;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-ExtraBold";
	src: url("/fonts/IntegralCF-ExtraBold.otf") format("opentype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-ExtraBoldOblique";
	src: url("/fonts/IntegralCF-ExtraBoldOblique.otf") format("opentype");
	font-weight: 800;
	font-style: oblique;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-Heavy";
	src: url("/fonts/IntegralCF-Heavy.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-HeavyOblique";
	src: url("/fonts/IntegralCF-HeavyOblique.otf") format("opentype");
	font-weight: 900;
	font-style: oblique;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-Medium";
	src: url("/fonts/IntegralCF-Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-MediumOblique";
	src: url("/fonts/IntegralCF-MediumOblique.otf") format("opentype");
	font-weight: 500;
	font-style: oblique;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-Regular";
	src: url("/fonts/IntegralCF-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IntegralCF-RegularOblique";
	src: url("/fonts/IntegralCF-RegularOblique.otf") format("opentype");
	font-weight: normal;
	font-style: oblique;
	font-display: swap;
}
