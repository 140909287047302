@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./dark.scss";

/* Addons */

body {
	background-color: #1e1e1e;
}

.cms-body {
	background-color: #111111;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.z-0 {
	z-index: 0;
}

.z-5 {
	z-index: 5;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #000000 #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar-track {
	background: #00000000;
}

*::-webkit-scrollbar-thumb {
	background-color: #0000005e;
	border-radius: 9px;
	border: 1px solid #ffffff57;
}
